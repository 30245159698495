class itemsBreakcrumbComponent extends Component {

    static name() {
        return "itemsBreakcrumb";
    }

    static componentName() {
        return "itemsBreakcrumb";
    }

    getMethods() {
        return {
            goBack:this.goBack,
            addFilterCategory:this.addFilterCategory
        };
    }

    goBack(){
        this.$router.go(-1);
    }

    addFilterCategory(cat) {
        if(cat) {
            this.$store.commit('updateAppState', 'loading');
            let objectFilter = {};
            objectFilter.name = uuidv4();
            objectFilter.type = 'globalClassification';
            //objectFilter.condition = `x.Classification.split(',').indexOf('${cat.code}')!=-1`;
            objectFilter.condition = `x.ClassificationPath.hasOwnProperty('${cat.code}')`;
            objectFilter.label = cat.code;
            objectFilter.filterField = "Classification";
            this.$store.dispatch('addGlobalFilter', objectFilter);
            this.$store.dispatch('updateCategory', cat);
            window.scrollTo(0, 0);
            //window.Event.$emit("category-select");
            this.emitEvent("category-select");
            //console.log('after emit event');
            //this.$store.commit('updateAppState', "loading");
            this.$router.push('/items?category=' + encodeURI(`${cat.code}__${cat.name}`))
        }
    }

    getTemplate() {
        return `<div class="col-sm-12 breadcrumb-container">
                   <nav aria-label="breadcrumb">
                          <ol class="breadcrumb">
                             <li class="go-back" title="Regresar al Listado" @click="goBack"><i class="fas fa-chevron-left"></i></li>
                             <li class="breadcrumb-item"><router-link :to="'/home'">{{tr("Home")}}</router-link></li>
                             <template v-for="cat in $store.getters.getCategoriesHistory">
                                <li class="breadcrumb-item" :class="{active: $store.state.cat_selected.code==cat.code}">
                                    <span :key="'breakcrumb'+cat.name" :class="{'btn-link': $store.state.cat_selected.code!=cat.code}" @click="addFilterCategory(cat)">{{capitalize(cat.name)}}</span>
                                </li>
                            </template>
                          </ol>
                  </nav>
               </div>`;
    }
}

itemsBreakcrumbComponent.registerComponent();